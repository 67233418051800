
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.app-loader-container {
  .spinner-layout {
    top: 10%;
  }
}

.overlay {
  @include position(fixed, 0, 0, null, null);
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadein 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 100000003;
}