
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.slider-container {
  .slide-box > img {
    width: 100%;
    height: 475px;
    object-fit: contain;

    @include mobile {
      height: 300px;
      object-fit: cover;
      object-position: center center;
    }
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 10px;
    border: 0;
    border-radius: 50%;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}