
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.cart {
  display: flex;
  align-items: center;

  .cart-icon {
    position: relative;
    height: 27px;
    width: 27px;

    .cart-count {
      position: absolute;
      right: 0;
      top: 0;
      left: 65%;
      background-color: red;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 10px;
      height: 14px;
      width: 14px;
    }
  }

  .cart-text {
    font-family: 'Value', sans-serif;
    font-weight: 600;
    margin-left: 10px;
    color: $blue;
  }
}

.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-cart-img {
    width: 200px;
    margin-top: 30px;

    @include mobile {
      width: 150px;
      margin-top: 20px;
    }
  }
  .empty-cart-disclaimer {
    padding: 0 15px;
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 32px;

    @include mobile {
      padding: 0;
      font-size: 28px;
    }
  }
}