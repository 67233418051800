
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.guide-btn-text {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: $medium_gray;
  font-family: 'Value', sans-serif;
  text-decoration: underline;
  background: none;
  border: none;
  outline: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.guide-steps-container {
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  & .guide-title {
    margin-bottom: 16px;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }

  & .guide-description {
    font-size: 22px;
    text-align: justify;
  }
  & .guide-steps-title {
    margin: 0;
  }

  & .guide-steps-list {
    margin-top: 10px;
    padding-left: 20px;
    font-size: 22px;

    & li {
      margin-bottom: 16px;
    }
  }

  @media only screen and (max-width: 634px) {
    padding: 0 16px;
  }
  @media only screen and (max-width: 532px) {
    padding: 0;

    & .guide-title {
      margin-bottom: 8px;
      font-size: 22px;
    }

    & .guide-description {
      font-size: 16px;
    }

    & .guide-steps-list {
      padding-left: 24px;
      font-size: 16px;

      & li {
        margin-bottom: 8px;
      }
    }
  }
}

.sizes-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .img-size-guide {
    width: 150px;
    margin-right: 32px;
  }

  & .table {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    & .row {
      display: flex;
      flex: 1;
    }

    & .cell {
      flex: 1;
      padding: 8px;
      text-align: center;
      border: 1px solid $gray;
      border-right: none;
      border-bottom: none;

      &:nth-last-child(1) {
        border-right: 1px solid $gray;
      }
    }

    & .table-title {
      padding: 16px 24px;
      text-transform: uppercase;
      font-weight: normal;
      border: 1px solid $gray;
      border-bottom: none;
    }

    & .header-cell {
      font-weight: bold;
      text-transform: uppercase;
    }

    & .data-row:nth-child(2n-1) {
      background-color: $gray_100;
    }

    & .data-row:nth-last-child(1) {
      border-bottom: 1px solid $gray;
    }
  }

  @include mobile {
    flex-direction: column;

    & .img-size-guide {
      order: 2;
      margin-top: 32px;
      margin-right: 0;
    }
  }
}

.guide-sizes-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  & .row {
    display: flex;
    flex: 1;
  }

  & .cell {
    flex: 1;
    padding: 8px;
    text-align: center;
    border: 1px solid $gray;
    border-right: none;
    border-bottom: none;

    &:nth-last-child(1) {
      border-right: 1px solid $gray;
    }
  }

  & .table-title {
    padding: 16px 24px;
    text-transform: uppercase;
    font-weight: normal;
    border: 1px solid $gray;
    border-bottom: none;
  }

  & .header-cell {
    font-weight: bold;
    text-transform: uppercase;
  }

  & .data-row:nth-child(2n-1) {
    background-color: $gray_100;
  }

  & .data-row:nth-last-child(1) {
    border-bottom: 1px solid $gray;
  }
}
