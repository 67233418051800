
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.confirmation-block {
  display: flex;
  flex-direction: column;

  .image-block {
    display: flex;
    justify-content: center;

    .warning-image {
      height: 65px;
    }
  }

  p {
    font-family: 'Value', sans-serif;
    font-size: 16px;
  }

  .warning {
    font-weight: bold;
  }

  .buttons {
    display: flex;
    margin-top: 40px;

    @include mobile-portrait {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }

    .button-item {
      margin-right: 10px;
      width: 200px;
      font-size: 14px;

      @include mobile-portrait {
        margin-top: 10px;
      }
    }
  }
}