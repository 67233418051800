
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.social-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;

  @include mobile-portrait {
    width: 100%;
  }

  .social-modal-title {
    padding: 0 15px;
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 26px;
    margin-bottom: 10px;

    @include mobile {
      padding: 0;
      font-size: 20px;
    }
  }

  .social-modal-info {
    margin-top: 15px;
    font-family: 'Value', sans-serif;
    font-size: 16px;
    text-align: center;
  }

  .social-modal-note {
    margin-top: 15px;
    font-family: 'Value', sans-serif;
    font-size: 14px;
    text-align: center;
  }
}