
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.banner-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: $banner_height;
  background-color: $banner_bg;
  z-index: 101;

  @include tablet {
    height: $banner_tablet_height;
  }
  @include max_width(320px) {
    height: $banner_narrow_screen_height;
  }

  .banner {
    @include standard_side_padding;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 8px 0;
      color: $banner_text;
      font-family: 'Value', sans-serif;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  &.warn {
    background-color: $banner_warn_bg;

    .banner p {
      color: $white;
    }
  }
}