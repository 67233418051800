
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.container {
  @include standard_side_padding;
  display: flex;
  justify-content: center;
  max-width: $content_max_width;
  margin-left: auto;
  margin-right: auto;

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 914px;
    margin-top: 64px;
    font-family: 'Value', sans-serif;
    font-size: 16px;
    line-height: 24px;

    @include tablet {
      width: fit-content;
    }

    .title {
      width: 100%;
      margin: 0 0 24px;
      padding-bottom: 32px;
      font-size: 32px;
      font-family: 'Vanitas', sans-serif;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      border-bottom: 1px solid $gray_300;
    }

    .description-block {
      text-align: center;
      margin: 0 0 16px;
      font-size: 14px;

      p {
        margin: 0;
      }
    }
  }
}

.order-total-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 50px;

  .row {
    font-family: 'Value', sans-serif;
    font-size: 16px;
    font-weight: 400;

    &.total-price {
      margin-top: 24px;
      font-size: 24px;
    }
    &:first-child {
      margin-top: 0;
    }

    span:first-child {
      margin-right: 8px;
      font-weight: 500;
    }
  }
}

.tracking-info-container {
  align-self: flex-start;
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .tracking-info-header {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: 'Vanitas', sans-serif;
    font-size: 32px;
    font-weight: 700;
  }

  .row {
    font-family: 'Value', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  .link {
    font-family: 'Value', sans-serif;
    font-size: 16px;
    color: $medium_gray;
  }
}

.orders-list {
  .item {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid $gray_300;

    @include mobile {
      align-items: flex-start;
    }

    .item-image {
      width: 160px;

      @include mobile {
        width: 120px;
      }
    }
    .item-details {
      display: flex;
      flex-direction: column;
      margin: 0 24px;
      width: 100%;

      @include mobile {
        margin: 0 12px;
      }

      .top-section {
        display: flex;
        padding: 8px 0;
        width: 100%;
        font-size: 32px;
        font-family: 'Vanitas', sans-serif;
        font-weight: 700;
        line-height: 40px;
        border-bottom: 1px solid $gray_300;

        a {
          font-family: 'Vanitas', sans-serif;
          font-weight: 400;
          font-size: 28px;
          letter-spacing: 2px;
          color: $black;

          @include tablet {
            font-size: 22px;
          }
        }
        .product-name {
          line-height: 1;
        }

        .quantity {
          vertical-align: center;
          padding-left: 8px;
          color: $medium_gray;
          white-space: nowrap;
        }

        @include mobile {
          font-size: 28px;
          line-height: 32px;
        }
      }
      .bottom-section {
        display: grid;
        grid-template-columns: 1fr 1fr auto auto;
        grid-gap: 16px;
        padding: 8px 0;

        @include max_width(500px) {
          grid-template-columns: 1fr;
          grid-gap: 8px;
          padding-bottom: 0;
        }

        .status {
          text-align: center;
          @include max_width(500px) {
            text-align: left;
          }
        }
      }
    }
  }
}