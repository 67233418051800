
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.referral-container {
  flex: 1;
  display: flex;
  flex-direction: row;

  @include tablet {
    flex-direction: column;
  }

  .left-block {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    background:$blue;

    .banner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 400px;
      height: 220px;
      background: $new_beige;
      border-radius: 20px;

      .text {
        margin: 15px 0 0 0;
        font-family: 'Vanitas', sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #5C5C5C;
      }

      .logo {
        width: 140px;
      }

      @include mobile {
        width: 90%;
        height: 200px;
      }
    }
  }

  .right-block {
    flex: 1;
    padding: 0 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      padding: 0 30px;
    }

    .info {
      font-family: 'Value', sans-serif;
      font-size: 14px;
      line-height: 24px;
      margin-top: 0;
    }

    .stats-container {
      display: flex;
      align-items: center;
      .stats-line {
        border-left: 6px solid $blue;
        height: 50px;
      }
      .stats-text {
        font-family: 'Vanitas', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        margin-left: 5px;
      }
      .stats-number{
        font-family: 'Vanitas', sans-serif;
        font-size: 50px;
        font-weight: 700;
        text-align: center;
        margin-left: 10px;
        margin-top: 5px;
      }
    }

    .share-container {
      margin-top: 40px;
      display: flex;
      align-items: end;
      .share-input {
        width: 300px;
        flex:2;
        .input {
          height: 48px;
        }
        .input-title {
          font-family: 'Vanitas', sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          margin-bottom: 5px;
        }
        .input-link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .share-button {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }

    }
  }
}

.error-modal-referral {
  h3 {
    margin: 0;
  }
  .error-buttons-block {
    width: 100%;

    button {
      width: 50% !important;

      @include mobile {
        width: 100% !important;
      }
    }
  }
}



