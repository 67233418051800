
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.input-container {
  position: relative;
  width: 100%;
  margin-top: 15px;
  font-family: 'Value', sans-serif;
  text-align: left;

  @include mobile {
    width: 100%;
  }

  .label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }

  input[name=password]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
  }
}

.input:focus {
  outline: none;
  border-radius: 12px;
  box-shadow: 0 0 5px 0  $blue;
}

.input {
  height: 45px;
  line-height: 45px;
  border-radius: 12px;
  padding: 14px;
  background-color: inherit;
  font-family: 'Value', sans-serif;
  font-size: 14px;
  width: 100%;
  border: 1px solid #ccc;

  &:disabled {
    background-color: $gray_300;
    opacity: .5;
  }

  &::placeholder {
    font-size: 14px;
    color: #595959;
    font-family: 'Value', sans-serif;
  }
}

input:focus {
  outline: none;
}

.error {
  border-color: #ff1313;
}

.error-msg {
  color: #ff1313;
  display: block;
  font-size: 12px;
  margin-top: 6px;
}