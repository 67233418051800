
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.modal-container {
  .modal-title {
    padding: 0 15px;
    margin: 24px 0 0;
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 24px;

    @include mobile {
      padding: 0;
      font-size: 20px;
    }
  }

  .modal-text {
    font-family: 'Value', sans-serif;
    font-size: 14px;
    text-align: center;
    opacity: 0.6;
  }

  .modal-btn {
    font-family: 'Value', sans-serif;
    font-size: 13px;
    width: 150px;
    height: 40px;
    margin-top: 15px;
  }
}