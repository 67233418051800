@mixin mobile {
  @media only screen
  and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media only screen
  and (max-width: 1024px) {
    @content;
  }
}

@mixin max_width($maxWidth) {
  @media only screen
  and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mobile-portrait {
  @media only screen
  and (max-width: 480px) {
    @content;
  }
}

@mixin side_padding($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin standard_side_padding {
  @include side_padding($standard_padding);
  @include tablet {
    @include side_padding($tablet_padding);
  }
  @include mobile {
    @include side_padding($mobile_padding);
  }
  @include mobile-portrait {
    @include side_padding($mobile_portrait_padding);
  }
}

@mixin font-size($font-size) {
  @include rem(font-size, $font-size)
}

@mixin section-button {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
  display: inline-block;
  font-family: "Proxima Nova", sans-serif;
  line-height: 1;
  padding: 22px 64px 20px;
  text-decoration: none;
  font-size: 14px;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}