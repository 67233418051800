
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.delete-container {
  @include standard_side_padding;
  display: flex;
  flex-direction: column;
  max-width: $content_max_width;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include tablet {
    margin-top: 30px;
  }
}