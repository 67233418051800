
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.pw-icon {
  position: absolute;
  right: 0;
  height: 45px;
  padding: 10px;
}

.error {
  border-color: #ff1313;
}