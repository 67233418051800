$white: #fff;
$black: #111;
$border_color: #cccccc;
$border_light_color: #e9e9e9;
$beige: #f9f4ed;
$beige_light: #fbf8f3;
$beige_dark: #f9f3ec;
$new_beige: #F7EDDF;
$gray_100: #f2f2f2;
$gray_200: #e5e5e5;
$gray_300: #dddddd;
$gray: #c0c0c0;
$hidden_gray: #F0F0F0;
$medium_gray: #888888;
$dark_gray: #221e1f;
$red: #ff1313;
$blue: #143641;
$blue-1000: #246175;
$blue-900: #005c99;
$rust: #cc7b6a;
$purple: #542f26;
$blue_link: #005c99;

$chart_blue: #97bdd1;
$chart_green: #4fd023;
$chart_red: #ff3f0a;
$chart_orange: #ff950a;
$chart_yellow: #ffdd0a;

$banner_warn_bg: #f15353;
$banner_bg: #edd1c9;
$banner_text: #143641;

$notification_maroon: #a05d4b;

$prepare_dark: #a76351;
$prepare_medium: #d7aca1;
$prepare_light: #cfaba1;
$prepare_xlight: #e9d7d3;

$reclaim_dark: #b4bec7;
$reclaim_medium: #d9dee3;
$reclaim_light: #eceef1;

$surpass_dark: #88958f;
$surpass_medium: #cfd5d2;
$surpass_light: #e0e4e2;

$bronze: #946e46;

$content_max_width: 1280px;
$standard_padding: 40px;
$tablet_padding: 34px;
$mobile_padding: 24px;
$mobile_portrait_padding: 20px;

$h1_size: 22px;
$body_size: 16px;

$section_title_size: 13px;
$section_description_size: 18px;

// Public nav
$nav_height: 94px;
$nav_tablet_height: 73px;
$nav_link_size: 13px;
$nav_link_pad: 4px;
$nav_sub_padding: 16px;
$nav_sub_link_pad: 4px;
$nav_sub_item_pad: 2px;
$nav_left_pos: -($nav_sub_padding + $nav_sub_link_pad - $nav_link_pad +
      $nav_sub_item_pad);
$nav_text_color: #31383F;
$nav_text_color_hover_state: #32343a;

// App page nav
$page_link_space: 14px;
$page_link_space_tablet: 8px;

// Cart block
$cart_block_height: 50px;

// Banner block
$banner_height: 35px;
$banner_tablet_height: 55px;
$banner_narrow_screen_height: 75px;
