
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.giveaway-container {
  text-align: center;

  .info {
    font-weight: 400;
    font-size: 16px;
  }
}