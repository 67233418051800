
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.dropdown-container {
  position: relative;
  margin-bottom: 15px;
  font-family: 'Value', sans-serif;
  text-align: left;

  .label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 12px;
    padding: 14px;
    background-color: inherit;
    width: 100%;
    border: 1px solid $gray_300;

    & > span {
      font-size: 16px;
      font-family: 'Value', sans-serif;

      &.placeholder {
        color: $medium_gray;
      }
    }
  }

  button[aria-expanded="true"] {
    border-radius: 12px 12px 0 0;
    border: 1px solid #133641;
  }

  .menu {
    position: absolute;
    z-index: 100;
    width: 100%;
    overflow-y: auto;
    max-height: 13rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: 1px solid #ccc;
    border-radius: 0 0 12px 12px;
    background-color: $white;

    ul {
      padding: 0;
      margin: 0;
    }

    .option {
      padding: 7px 0 7px 14px;
      margin: 0;
      font-size: 16px;
      display: flex;
      justify-items: center;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .search-control {
      height: 40px;
      padding: 14px;
      background-color: inherit;
      font-family: 'Value', sans-serif;
      font-size: $body_size;
      width: 100%;
      border: 1px solid $border_color;

      &::placeholder {
        font-size: $body_size;
        color: $medium_gray;
        font-family: 'Value', sans-serif;
      }
    }


  }
}
