
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.info-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .info-modal-title {
    padding: 0 15px;
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 26px;
    margin-bottom: 5px;

    @include mobile {
      padding: 0;
      font-size: 20px;
    }
  }
  .info-description {
    text-align: center;
    p {
      font-family: 'Value', sans-serif;
      font-size: 16px;

      @include mobile {
        font-size: 14px;
      }
    }
  }
}