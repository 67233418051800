
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.reviews-slider-container {
  background-color: #fcfaf8;
  color: #31383f;
  width: 100%;

  .slide-box p {
    font-size: 16px;
    text-align: center;
    font-family: Value, sans-serif;

    &:last-child {
      font-weight: 600;
    }
  }

  .indicators {
    padding-inline-start: 0 !important;
    li {
      width: 1em !important;
      height: 1em !important;
    }
    button::before {
      width: 1em !important;
      height: 1em !important;
    }
  }
}

.reviews-slider-container-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;

  @include mobile {
    padding-left: 30px;
    padding-right: 30px;
  }

  h3 {
    color: #1a1b1f;
    margin-top: 40px;
    margin-bottom: 10px;
    font-family: Value, sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
  }

  .react-slideshow-container {
    padding: 40px 85px 0 !important;

    @include mobile {
      padding: 0 !important;
    }
  }
}

.reviews-slider-container > div {
  object-fit: contain;
  @include mobile {
    object-fit: cover;
    object-position: center center;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: #fcfaf8;

    &:hover {
      cursor: pointer;
    }

    @include mobile {
      display: none;
    }
  }
}
