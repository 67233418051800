
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.referral-block {
  width: 100%;
  position: absolute;
  left: 0;
  top: $nav_height;
  background: $new_beige;
  display: flex;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0 10px;

  @include tablet {
    top: $nav_tablet_height;
  }
  @include mobile {
    top: $nav_tablet_height;
  }
  
  .title {
    max-width: 280px;
    font-family: 'Vanitas', sans-serif;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
  }

  .info {
    max-width: 400px;
    font-family: 'Value', sans-serif;
  }
}

.referral-margin {
  margin-top: 220px !important;
}

.referral-note {
  display: flex;
  flex-direction: column;
  margin: 48px 0;
  max-width: 400px;
  text-align: start;

  @include tablet {
    padding: 0 10px;
  }

  p a {
    font-size: 12px;
    color: $black;
  }
}


/* Social buttons block */
.social-block {
  width: 400px;

  @include mobile-portrait {
    width: 100%;
    padding: 0 32px;
  }
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  & span {
    font-size: 14px;
    color: rgba($blue, 0.4);
    padding: 0 10px;
    background-color: white;
    z-index: 1;
    cursor: default;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($blue, 0.4);
  }
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.social-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 12px 16px;
  height: 46px;
  font-family: 'Value', sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: .5px;
  color: $blue;
  background: white;
  border: 1px solid $blue;
  border-radius: 12px;
  cursor: pointer;

  & span {
    flex-grow: 1;
    transform: translateY(1px);
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}



