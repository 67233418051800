
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  font-size: 13px;
  font-family: 'Value', sans-serif;
  font-weight: 500;
  letter-spacing: .5px;
  border-radius: 12px;
  cursor: pointer;

  .spinner {
    border: 3px solid rgba($white, 0.5);
    border-top: 3px solid $white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &:disabled {
    color: $medium_gray;
    background: $hidden_gray;
  }
}

.form-button {
  background-color: $prepare-dark;
  color: #fff;
  width: 190px;
  border: none;
  margin: 15px auto 0;
}

.dark-button {
  background: $blue;
  color: $white;
  border: none;

  .spinner {
    border: 3px solid rgba($beige, 0.3);
    border-top: 3px solid rgba($beige, 0.8);
  }
}

.light-button {
  background: $white;
  color: $blue;
  border: 1px solid $blue;

  .spinner {
    border: 3px solid rgba($blue, 0.2);
    border-top: 3px solid $blue;
  }

  &:disabled {
    border: none;
  }
}
