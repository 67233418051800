
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000003;

  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .loading-spinner {
      width: 50px;
      height: 50px;
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      animation: spinner 1.5s linear infinite;
      z-index: 102;

      &.beige {
        border-top: 5px solid $prepare_dark;
      }
      &.blue {
        border-top: 5px solid $blue;
      }
    }
  }
}

