
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.billing-history-container {
  position: relative;
  min-width: 400px;
  height: 50vh;
  overflow-y: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include max_width(520px) {
    min-width: 300px;
  }
  @include max_width(440px) {
    min-width: auto;
  }

  .billing-history-title {
    position: sticky;
    top: -2px;
    background-color: white;
    padding-bottom: 16px;
    z-index: 1;
    margin: 0 8px 0 0;
    font-family: Vanitas, sans-serif;
  }

  .billing-history-list {
    padding-left: 16px;
    padding-right: 8px;
    margin-top: 0;
    font-family: Value, sans-serif;
    font-size: 16px;

    .billing-history-item {
      margin-bottom: 20px;
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;

      .item-content {
        display: grid;
        gap: 8px;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;

        span:first-child {
          grid-column: span 2;
        }
      }

      .link-wrapper {
        justify-self: end;

        .link-btn {
          padding: 0;
          display: inline-block;
          text-decoration: underline;
          font-family: Value, sans-serif;
          font-size: 14px;
          background: none;
          border: none;
          color: $blue_link;
          cursor: pointer;

          &:hover {
            text-decoration: none;
          }
        }

        .spinner-wrapper {
          padding-right: 20px;

          .spinner {
            width: 20px;
            height: 20px;
            border: 3px solid rgba($blue, 0.2);
            border-top: 3px solid $blue;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }

      @include mobile() {
        grid-template-columns: 1fr;

        .link-wrapper {
          justify-self: start;
        }
      }
    }
  }
}