
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.confirmation-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .confirmation-description {
    text-align: center;
    p {
      font-family: 'Value', sans-serif;
      font-size: 16px;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  .confirmation-modal-btn-container {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;

    button {
     width: 170px;
    }

    @include mobile-portrait {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}