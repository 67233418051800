
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.reasons-container {
  width: 90%;
  .title {
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 24px;
  }

  .reason-error-message {
    font-family: 'Value', sans-serif;
    text-align: center;
    font-size: 14px;
    color: $red;
  }

  .options-container {
    .option-item {
      display: flex;
      padding: 10px 0;

      input {
        margin: 0;
      }

      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 16px;
        height: 16px;
        background-color: #eceef1;
        border-radius: 50%;
      }

      input[type="radio"]:checked {
        background-clip: content-box;
        background-color: $blue;
        border: 1px solid $blue;
        box-shadow: inset 0 0 0 2px white;
      }

      label {
        margin-left: 10px;
        font-family: 'Value', sans-serif;
      }
    }

    .option-more {
      margin-top: 10px;
      font-family: 'Value', sans-serif;

      textarea {
        font-family: 'Value', sans-serif;
        display: block;
        width: 100%;
        height: 100px;
        margin: 10px 0;
      }
    }
    .buttons {
      align-items: center;
      display: flex;
      flex-direction: column;

      .button-item {
        width: 150px;
        margin: 10px 0;
      }
    }
  }
}