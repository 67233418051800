/*//EB Garamond*/
@font-face {
    font-family: 'EB Garamond';
    src: url('./EB_Garamond/EBGaramond-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'EB Garamond';
    src: url('./EB_Garamond/EBGaramond-Medium.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'EB Garamond';
    src: url('./EB_Garamond/EBGaramond-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'EB Garamond';
    src: url('./EB_Garamond/EBGaramond-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}


/*Vanitas*/
@font-face {
    font-family: 'Vanitas';
    src: url('./Vanitas/Vanitas-Bold.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Vanitas';
    src: url('./Vanitas/Vanitas-Extrabold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Vanitas Black';
    src: url('./Vanitas/Vanitas-Black.ttf');
    font-weight: 700;
    font-display: swap;
}

/*Proxima Nova*/
@font-face {
    font-family: 'Proxima Nova';
    src: url('./ProximaNova/ProximaNova Regular.otf');
    font-weight: 400;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('./ProximaNova/ProximaNova Semibold.otf');
    font-weight: 600;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('./ProximaNova/ProximaNova Bold.otf');
    font-weight: 700;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('./ProximaNova/ProximaNova RegularIt.otf');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('./ProximaNova/ProximaNova Thin.otf');
    font-weight: 300;
}

/* Value Sans */
@font-face {
    font-family: 'Value';
    src: local('Value'),
        url('./ValueSans/Value.woff2') format('woff2'),
        url('./ValueSans/Value.woff') format('woff'),
        url('./ValueSans/Value.otf') format('otf'),
        url('./ValueSans/Value.ttf') format('ttf'),
        url('./ValueSans/Value.eot') format('oet');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Value';
    src: local('Value-Medium'),
        url('./ValueSans/Value-Medium.woff2') format('woff2'),
        url('./ValueSans/Value-Medium.woff') format('woff'),
        url('./ValueSans/Value-Medium.otf') format('otf'),
        url('./ValueSans/Value-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
