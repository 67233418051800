
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-overlay {
  width: 100vw;
  height: 100vh;
  @include position(fixed, 0, 0, null, null);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000002;
  animation: fadein 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.modal-root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  padding: 24px;
  margin: 32px;
  background-color: rgba(255, 255, 255);
  border-radius: 12px;
  overflow-y: auto;
  z-index: 1000;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modal-close-btn {
  @include position(absolute, 6px, 6px, null, null);
  width: 24px;
  height: 24px;
  padding: 4px;
  box-sizing: content-box;
  background: none;
  border: none;
  border-radius: 50%;
  outline: none;
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.04)
      radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%)
      center/15000%;
    border-radius: 50%;
    cursor: pointer;
  }

  &::after,
  &::before {
    content: '';
    @include position(absolute, 50%, 50%, null, null);
    margin-top: -.75px;
    margin-right: -10px;
    width: 20px;
    height: 1.5px;
    background-color: #9e9e9e;
  }

  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:active {
  background-color: #e6e6e6;
  border-radius: 50%;
  background-size: 100%;
  transition: background 0s;
}
