
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Value', sans-serif;
  font-size: 12px;
  line-height: 24px;
  position: relative;
  gap: 5px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox .checkmark {
  height: 20px;
  width: 20px;
  border: 0.1em solid $black;
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox svg {
  visibility: visible;
}

.checkbox input:not(:checked) ~ .checkmark svg {
  visibility: hidden;
}