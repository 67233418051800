
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.profile-edit-container {
  margin-top: 80px;

  .profile-edit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: 0 auto 40px;

    @include mobile-portrait {
      width: 100%;
    }
  }

  .profile-picture-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
  }
  .label-wrapper {
    cursor: pointer;
    width: 300px;
    height: 300px;

    @include mobile {
      width: 200px;
      height: 200px;
    }
  }

  .profile-picture {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }

  .camera-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20%;
    background-color: #000;
    opacity: 0.3;
  }

  .profile-edit-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .email-field-social-login {
    position: relative;
    width:  100%;

    svg {
      position: absolute;
      bottom: 30px;
      left: 16px;
    }

    input {
      padding-left: 40px;
    }
  }
}




