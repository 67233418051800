
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.error-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-buttons-block {
    display: flex;
    flex-direction: row;
    gap: 32px;

    @include mobile {
      flex-direction: column;
      gap: 12px;
    }
  }

  .error-modal-title {
    padding: 0 15px;
    text-align: center;
    font-family: 'Vanitas', sans-serif;
    font-size: 26px;

    @include mobile {
      padding: 0;
      font-size: 20px;
    }
  }

  .error-modal-message {
    margin-bottom: 32px;
    padding: 0;
    text-align: center;
    font-family: 'Value', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
}

.error-modal-oauth {
  padding: 0 12px;

  .error-modal-message {
    text-align: left;
  }
  .error-buttons-block {
    @include mobile {
      width: 100%;
    }

    button {
      width: 180px !important;

      @include mobile {
        width: 100% !important;
      }
    }
  }
}