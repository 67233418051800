
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid #ccc;

  th:first-child {
    text-align: left;
    width: 40%;
  }
  th {
    border-bottom: 1px solid #ccc;
    font-family: Value,sans-serif;
    font-weight: 400;
    padding: 0 0 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;

    @include tablet {
      font-size: 12px;
    }
  }

  td:first-child {
    text-align: left;
  }
  td:nth-child(2) {
    justify-content: center;
    display: flex;
  }
  td {
    font-family: Value,sans-serif;
    font-weight: 400;
    line-height: 1;
    padding: 15px 0;
    vertical-align: top;
    font-size: 16px;
    text-align: center;

    @include tablet {
      font-size: 14px;
    }

    .table-content {
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .status-block {
      border-radius: 6px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      vertical-align: center;
      width: 100px;
      text-transform: capitalize;

      @include tablet {
        width: 70px;
        height: 20px;
        line-height: 20px;
      }
    }

    .status-block-active {
      background: $blue;
      color: $white;
    }
    .status-block-inactive {
      background: $hidden_gray;
      color: $blue;
    }
  }
}

.membership-links {
  margin-top: 30px;
  .link {
    margin: 5px 0;

    @include tablet {
      margin: 15px 0;
    }
  }
}