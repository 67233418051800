
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.e-public-header {
  background-color: $white;
  z-index: 10000001;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 1px 1px 8px 2px hsl(0deg 0% 53% / 9%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: $nav_height;

  @include tablet {
    min-height: $nav_tablet_height;
  }
}

.b-public-nav {
  @include standard_side_padding;
  transform-style: preserve-3d;
  background-color: white;
  display: flex;
  justify-content: space-between;
  font-family: 'Value', sans-serif;
  margin: 0 auto;
  max-width: $content_max_width;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  
  @include tablet {
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @include mobile {
    height: $nav_tablet_height;
  }

  &.m-admin-bar-visible {

    .e-link-wrap {

      &.m-show {
        width: 100%;
        @include tablet {
          top: 32px;
        }
          @include mobile {
            top: 46px;
          }
      }

    }

  }

  .e-hamburger-wrap {
    display: none;
    z-index: 100;
    padding-right: 40px;
    padding-top: 5px;
    
    @include tablet {
      align-items: center;
      display: flex;
      height: 28px;
      width: 28px;
      padding: 0;
      margin-right: 20px;
    }

    .e-hamburger {
      cursor: pointer;
      width: 100%;
    }

  }

  .e-logo-title {
    margin: 23px 0 0 -1px;
    text-transform: capitalize;

    @include tablet {
      margin: 6px auto auto -1px;
    }

    @include mobile {
      height: 13px;
      margin: 0;
    }

    .e-logo {
      height: 16.2px;
      width: 208px;
      
      @include mobile{
        height: 13px;
        max-width: 160px;
      }
    }
  }

  .e-link-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    
    .e-link-li>.e-link {
      display: block;
      color: $nav_text_color;
      font-weight: 500;
    }
    
    @include tablet {
      background-color: #fff;
      display: none;
      margin-top: 0;
      overflow-y: scroll;
      padding-bottom: 40px;
      left: 0;

      @keyframes slidedown {
        from {
          @include position(absolute, -300px, null, null, null);
          transform: translateZ(-10px);
        }
        to {
          @include position(absolute, 69px, null, null, null);
          transform: translateZ(-10px);
        }
      }
      
      @keyframes slideup {
        from {
          @include position(absolute, 69px, null, null, null);
          transform: translateZ(-10px);
        }
        to {
          @include position(absolute, -500px, null, null, null);
          transform: translateZ(-10px);
        }
      }
  

      &.m-show {
        display: block;
        width: 100%;
        animation: slidedown .4s 1 forwards;
        height: auto;
      }
      
      &.m-remove {
        width: 100%;
        display: block;
        animation: slideup .4s 1 forwards;
      }

      .e-link-list,
      .e-account-links {
        padding: 0 32px;

        .e-link-li,
        .e-account-item {
          text-align: center;
          width: 100%;
          height: 50px;

          .e-link {
            display: inline;
          }

        }

      }

    }
  }

  .e-link-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    line-height: 1;
    list-style-type: none;
    margin: 2.5px 10px 0 0;
    padding: 0;
    font-weight: 500;
    z-index: 100;

    @include tablet {
      display: block;
      width: auto;
      margin: 0 auto;
      padding-top: 30px !important;
    }

    .e-link-li {
      cursor: pointer;
      letter-spacing: 0.5px;
      font-size: $nav_link_size;

      @include tablet {
        margin: 0;
      }

      .e-link {
        opacity: 0.6;
        text-decoration: none;
        @include position(relative, null);
        padding: 20px;

        &:hover {
          opacity: 1;
          color: $nav_text_color_hover_state;
          background-color: $hidden_gray;
          border-radius: 10px;

          @include tablet {
            color: inherit;
            background-color: inherit;
            border-radius: unset;
          }
        }
      }
    }
  }

  .e-account-links {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    @include tablet {
      display: block;
    }

    .e-account-item {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      text-decoration: none;
      a {
        padding: 0 25px;
      }
      
      &.m-first {
        margin: 0 20px 0 0;
        border-radius: 12px;
        
        a {
          background-color: $blue;
          border-style: solid;
          border-width: 1px;
          border-color: #143641;
          border-radius: 12px;
          padding-top: 1px;
          min-width: 128.5px;
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
          
          div {
            letter-spacing: 0.6px;
            font-weight: 700;
            text-align: center;
            font-size: 13px;
            color: #ffffff;
            opacity: 1;
            line-height: 28px;
          }
        }
        
        @include tablet {
          margin: 0 0 16px 0;
          padding: 0;

          a, div {
            width: 100%;
          }
        }
      }
      
      &.m-second {
        border: 1px solid $blue;
        border-radius: 12px;
        min-width: 92px;
        height: 46px;
        a {
          text-align: center;
          padding-top: 2px;
          color: $blue;
          letter-spacing: 0.6px;
        }
        span {
          text-align: center;
          padding-top: 2px;
          color: $blue;
          letter-spacing: 0.6px;
        }
        @include tablet {
          margin-left: 0;
          width: 100%;
        }
      }
   
      .e-account-link {
        letter-spacing: 0.6px;
        text-align: center;
        color: #ffffff;
        opacity: 1;
        line-height: 28px;
        text-decoration: none;
        font-size: $nav_link_size;
        font-family: 'Value', sans-serif;
        font-weight: 500;
      }
    }
  }
}
