
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.error-message {
  color: $red;
  width: 300px;
  text-align: center;
  margin: 1px 0 5px 2px;
  font-size: 12px;
  font-family: "ProximaNova", sans-serif;
}