
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
@import './styles/Fonts/fonts.scss';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html, body, #root, #root>div {
  height: 100%;
}

html {
  line-height: 1.15;
  width: 100vw;
}

body {
  margin: 0;
  font-family: 'Value', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  color: #000;
  overflow-y: auto;
  opacity: 0;
}

body.fonts-loaded {
  opacity: 1;
  transition: opacity 0.1s;
}

.App {
  overflow-y: auto;
  margin: 0;

  .page-container {
    margin: $nav_height auto 0 auto;
    min-height: calc(100vh - #{$nav_height});
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet {
      margin: $nav_tablet_height 0 0;
      min-height: calc(100dvh - #{$nav_tablet_height});
    }
    &:has(.register-login-container) + .footer-container .links-container {
      @include max_width(480px) {
        border-top: none;
      }
    }
  }
}

h2 {
  font-family: 'Vanitas', sans-serif;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 2px;

  @include tablet {
    font-size: 24px;
  }
}

p {
  font-size: 14px;
}

a {
  color: $prepare-dark;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  outline-offset: 0;
}

//forms
.register-login-container {
  min-height: 600px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 480px;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;

  @include mobile-portrait {
    width: 100%;
  }

  .register-login-content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    font-family: 'Value', sans-serif;

    @include max_width(480px) {
      margin-top: 0;
      height: 100%;
    }

    p {
      display: inline;
    }

    .form-links {
      p {
        font-family: 'Value', sans-serif;
        font-size: 13px;
        color: $nav_text_color;
      }
    }

    a {
      text-decoration: underline;
      margin: 15px 0;
    }
    .register-login-note {
      margin: 0;
      margin-top: 10px;
      font-size: 12px;
      font-family: 'Value', sans-serif;
    }
  }
}

.form-header {
  margin: 0 auto 20px;
  font-size: 32px;
  font-family: 'Vanitas', sans-serif;
  letter-spacing: normal;

  @include tablet {
    font-size: 28px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 400px;

  @include mobile-portrait {
    width: 100%;
    padding: 0 32px;
  }
}
.error-block:not(:empty) {
  margin: 16px 0 16px 0;
}

.form-btn {
  width: 100%;
  margin-top: 16px;
}

.info-message {
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 22px;
  font-family: 'Value', sans-serif;
  margin: 30px 0 20px;
  padding: 0 20px;
}

.action-link {
  border-bottom: 1px solid #ccc;
  color: #000;
  font-family: 'Value', sans-serif;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;

  @include tablet {
    font-size: 11px;
  }
}

.navigate-link {
  color: $blue;
  font-family: 'Value', sans-serif;
  font-weight: 500;
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 13px;
  cursor: pointer;
}

.forgot-password-link {
  margin-top: 5px;
  align-self: flex-start;
}

.btn-link {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid $black;
  color: $black;
  line-height: 1;
  font-family: Value,sans-serif;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

