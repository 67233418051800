
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.e-link-li {
  span {
    display: block;
    padding: 20px;
    color: $nav_text_color;
    opacity: 0.6;

    @include tablet {
      margin-top: -15px;
      width: 100%;
    }

    &:hover {
      @include tablet {
        color: $nav_text_color_hover_state;
        background-color: inherit;
        border-radius: unset;
      }

    }
  }

  &[aria-expanded="true"] span {
    background-color: $hidden_gray;
    border-radius: 10px 10px 0 0;
  }
}

.dropdown {
  position: absolute;
  background-color: $white;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  list-style-type: none;

  @include tablet {
    position: relative;
    padding-top: 25px;
  }

  .e-link:hover {
    border-radius: 0 10px 10px !important;
  }
}
