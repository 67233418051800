
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.account-container {
  @include standard_side_padding;
  display: flex;
  flex-direction: column;
  max-width: $content_max_width;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 100px;

  @include tablet {
    margin-top: 30px;
  }

  h3 {
    border-bottom: 1px solid #ccc;
    font-family: 'Vanitas', sans-serif;
    font-weight: 400;
    margin: 0 0 20px;
    padding: 0;
    font-size: 38px;

    @include tablet {
      font-size: 28px;
    }
  }

  .membership-container {
    width: 100%;
    margin-bottom: 40px;
  }

  .user-info-container {

    .user-info p {
      font-family: Value, sans-serif;
      font-weight: 400;
      line-height: 1;
      padding: 0 0;
      margin: 10px 0;
      vertical-align: top;
      font-size: 16px;

      @include tablet {
        font-size: 14px;
      }
    }

    .account-links .action-link {
      margin-right: 15px;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      line-height: 1;
      border-bottom: 1px solid #ccc;
    }

    .external-identifiers-block {
      margin-top: 24px;
      font-family: Value, sans-serif;

      h4 {
        margin: 0 0 12px 0;
        font-weight: 500;
      }

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        span {
          transform: translateY(1px);
        }
      }
    }
  }
}