
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.cart-container {
  display: flex;
  flex: 1;

  @include tablet {
    flex: none;
    flex-direction: column-reverse;
  }

  .product-info {
    flex: 2;
    background: $beige-light;
    padding: 20px 20px 0 50px;

    a {
      font-family: 'Value', sans-serif;
      font-size: 14px;
      text-decoration-line: underline;
      color: #858585;
    }

    @include tablet {
      padding: 20px 20px 0 20px;
    }
  }

  .payment-info {
    flex: 1;
    padding: 20px 60px 0 20px;

    @include tablet {
      padding: 20px 20px 0 20px;
    }

    .price-block {
      display: flex;
      margin-top: 15px;

      .price-title {
        flex: 2;
        font-family: 'Vanitas', sans-serif;
        font-size: 24px;
      }

      .price-value{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-family: 'Vanitas', sans-serif;
        font-size: 24px;
        white-space: nowrap;
      }
    }

    .shipping-block {
      margin: 25px 0 25px 0;
    }

    .checkout-button {
      margin-top: 25px;
    }

    .guest-block {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .guest-question {
        text-align: center;
        font-family: 'Value', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 2;
      }
      .guest-button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}