
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.product-item {
  background: white;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  .price-block {
    display: flex;
    margin-bottom: 15px;

    .price-title {
      flex: 2;
      font-family: 'Vanitas', sans-serif;
      font-size: 24px;
    }

    .price-value {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      font-family: 'Vanitas', sans-serif;
      font-size: 24px;
    }

    .out-of-stock {
      color: red;
      font-family: 'Vanitas', sans-serif;
      font-size: 24px;
    }
  }

  .product-content {
    display: flex;


    @include mobile-portrait {
      flex-direction: column;
    }

    .product-images {
      display: flex;
      justify-content: center;

      img {
        width: 220px;
        height: auto;
      }

      @include mobile-portrait {
        flex-direction: column;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .product-values {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      @include mobile-portrait {
        margin-top: 10px;
        margin-left: 0;
      }

      .title {
        font-family: 'Value', sans-serif;
        font-size: 16px;
        color: $medium_gray;
        margin-right: 5px;
      }

      .value {
        font-family: 'Value', sans-serif;
        font-size: 14px;
        color: $blue;
        margin-right: 5px;
      }

      .product-count-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;

        .product-count {
          display: flex;
          align-items: center;

          span {
            font-family: 'Value', sans-serif;
          }

          .sign {
            font-size: 22px;
            color: $medium_gray;
            cursor: pointer;
          }

          .disable {
            color: $hidden_gray;
          }

          .count {
            font-size: 18px;
            margin: 0 30px;
            color: $blue;
          }

          .remove-button {
            margin-left: 30px;
            font-family: 'Value', sans-serif;
            font-size: 14px;
            color: #5D5A5B;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.unavailable {
  opacity: 0.5;
}