
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.shipping-info {
  display: flex;
  align-items: center;

  .shipping-icon {
    margin-right: 10px;
  }

  .shipping-text {
    font-family: 'Value', sans-serif;
    font-size: 16px;

    @include tablet {
      font-size: 12px;
    }
  }

  .shipping-free {
    background: #50A682;
    width: 40px;
    height: 20px;
    color: white;
    margin-left: 5px;
    text-align: center;
  }
}

.shipping-policy {
  font-family: 'Value', sans-serif;
  font-size: 16px;
  color: #a76351;
  margin-left: 34px;
  @include tablet {
    font-size: 12px;
  }
}
