
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.product-name {
  margin-top: 0;
  margin-bottom: 0;

  sup {

    font-size: 16px;
  }
}