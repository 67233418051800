
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.footer-container {
  width: 100%;
  padding: 0 20px 26px;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  a, p {
    text-decoration: none;
    color: $nav_text_color;
    font-family: 'Value', sans-serif;
    line-height: 28px;
    font-size: 14px;
  }
  
  .logo-link {
    max-width: 138px;
    max-height: 90px;
    .footer-logo {
      max-width: 138px;
      max-height: 90px;
    }

    @include mobile {
      margin-top: 20px;
      margin-bottom: 35px;
    }
  }
  
  .links-container {
    margin: 0 auto;
    max-width: 1100px;
    display: grid;
    align-items: start;
    grid-auto-columns: 1.25fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-areas: ".";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    padding: 50px 0 60px;                 
    
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;  
      padding-bottom: 24px;
      width: inherit;
    }
    
    
    .footer-links {
      display: flex;
      flex-direction: column;
      max-width: 1280px;
      margin-top: 31px;
      
      @include mobile {
        margin-bottom: 16px;
        margin-top: 35px;
        text-align: center;
      }
      
      a {
        list-style-type: none;
        margin-bottom: 15px;
        opacity: .7;
        color: #43464d;
      }
      
      a:hover, a:active {
        outline: 0;
        opacity: 1;
        color: $nav_text_color_hover_state;
      }
    }


    &:last-child {
      @include mobile {
        margin-bottom: 0;
      }
    }
  }

  .disclaimer-container {
    text-align: center;
    margin-bottom: 19px;
    padding-top: 30px;

    p {
      font-size: 12px;
      color: #31383f;
      line-height: 20px;
      opacity: 0.7;
      margin: 0;
    }
  }
}