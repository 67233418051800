
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.metro-pt-container {
  min-height: 600px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 480px;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;

  @include mobile-portrait {
    width: 100%;
  }

  .metro-pt-content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    font-family: 'Value', sans-serif;

    @include max_width(480px) {
      margin-top: 0;
      height: 100%;
    }
  }
}



.header {
  width: 85%;
  margin: 0 auto 30px;
  font-size: 32px;
  font-family: 'Vanitas', sans-serif;
  letter-spacing: normal;

  @include tablet {
    font-size: 28px;
  }
}

.details-block {
  width: 85%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  .details-info {
    font-family: 'Value', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #383838;
    text-align: justify;
    padding-bottom: 20px;
  }
}

.dropdown-container, .checkbox {
  width: 85%;
}

.continue-btn {
  font-family: 'Value', sans-serif;
  font-size: 13px;
  width: 85%;
  height: 40px;
  margin-top: 15px;
}
