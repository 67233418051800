
          @import "src/styles/variables";  
          @import "src/styles/mixins";  
          @import "src/styles/rem";
        
.corset {
  display: flex;
  position: relative;
  margin-bottom: 80px;

  @include tablet {
    margin-bottom: 40px;
  }

  .cart-block {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: $cart_block_height;
    background-color: #FCFAF8;

    .cart-item {
      @include standard_side_padding;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      max-width: $content_max_width;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }
  }

  .cart-block-fixed {
    @include tablet {
      position: fixed;
      top: $nav_tablet_height;
      width: 100%;
      box-shadow: 0 0 10px gray;
      z-index: 100;
      transition: 0.5s all ease;
    }
  }

  .product-block {
    @include standard_side_padding;
    display: flex;
    flex-direction: row;
    max-width: $content_max_width;
    margin-top: $cart_block_height;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include tablet {
      flex-direction: column;
    }

    .sub-title {
      font-family: 'Value', sans-serif;
      font-size: 16px;
      color: $medium_gray;
      align-self: end;
    }

    .product-info-1 {
      display: flex;
      flex-direction: column;
      flex: 3;

      .product-title-mobile {
        height: auto;
        padding-top: 20px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        display: none;

        @include tablet {
          display: block;
        }
      }

      .image-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 100px;
        max-width: 690px;
        width: 100%;
        height: auto;

        @include tablet {
          margin-top: 10px;
        }

        .image-caption {
          margin-top: 8px;
          font-family: 'Value', sans-serif;
          font-size: 16px;
        }
      }

      .shipping {
        margin-top: 10px;

        @include tablet {
          display: none;
        }
      }
    }

    .product-info-2 {
      flex: 2;
      margin-left: 30px;

      @include tablet {
        margin-left: 0;
      }

      .product-title {
        height: 110px;
        padding-top: 40px;
        margin-top: 0;
        margin-bottom: 0;

        @include tablet {
          display: none;
        }
      }

      .colors-price-block {
        display: flex;
        align-items: center;

        .colors {
          flex: 1;
          display: flex;

          .color-item {
            position: relative;
            width: 23px;
            height: 23px;
            border-radius: 5px;
            margin: 0 0 0 5px;
            cursor: pointer;

            .tooltip {
              font-family: 'Value', sans-serif;
              top: 25px;
              visibility: hidden;
              font-size: 12px;
              color: black;
              position: absolute;
              z-index: 1;
            }
          }

          .color-item:hover .tooltip {
            visibility: visible;
          }

          .color-item-active {
            border: 1px solid $white;
            outline: 1px solid $blue;
          }
        }

        .price {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          font-family: 'Vanitas', sans-serif;
          font-size: 32px;

          @include tablet {
            font-size: 24px;
          }
        }
      }

      .sizes-block {
        margin-top: 15px;

        .sizes-header {
          display: flex;
          justify-content: space-between;

          .sub-title {
            align-self: start;
          }
        }

        .sizes-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .sizes-item {
            flex: 1;
            display: flex;
            height: 45px;
            max-width: 85px;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            border: 1px solid #F0F0F0;
            border-radius: 10px;
            cursor: pointer;
          }

          .sizes-item-hidden {
            position: relative;
            color: $medium_gray;
            background-color: $hidden_gray;

            &:hover::after  {
              content: 'Out of stock';
              position: absolute;
              width: 84px;
              top: 120%;
              left: 50%;
              margin-left: -42px;
              padding: 8px;
              border-radius: 5px;
              font-family: 'Value', sans-serif;
              line-height: 1;
              text-align: center;
              background-color: $hidden_gray;
              box-shadow: 0 7px 9px 0 rgba(34, 60, 80, 0.1);
              border: none;
              outline: none;
              color: $medium_gray;
              z-index: 1;
            }
            &:hover::before {
              content: "";
              position: absolute;
              top: 97%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent transparent $hidden_gray transparent;
              z-index: 1;
            }
          }

          .sizes-item-active {
            border: 1px solid #143641;
            border-radius: 10px;
          }

          .sizes-item:first-child {
            margin-left: 0;
          }

          @include tablet {
            justify-content: flex-start;
          }
        }

        .size-error {
          font-size: 14px;
          color: $red;
          font-family: 'Value', sans-serif;
        }
      }

      .details-block {
        margin-top: 20px;

        .details-info {
          font-family: 'Value', sans-serif;
          font-size: 12px;
          line-height: 24px;

          & > sup {
            font-size: 8px;
          }
        }

        .details-section {
          margin-top: 5px;
          margin-left: 30px;

          .sizes-info-wrapper {
            margin-top: 12px;
            .sizes-info-container {
              img {
                width: 130px;
                margin-right: 16px;
              }

              @include max_width(1180px) {
                flex-direction: column;

                & .img-size-guide {
                  order: 2;
                  margin-top: 32px;
                  margin-right: 0;
                }
              }
              @include tablet {
                flex-direction: row;

                & .img-size-guide {
                  order: 0;
                  margin-top: 0;
                  margin-right: 32px;
                }
              }
              @include mobile {
                flex-direction: column;

                & .img-size-guide {
                  order: 2;
                  margin-top: 32px;
                  margin-right: 0;
                }
              }
            }
          }
        }

        .details-title {
          font-weight: 700;
        }

        .details-item {
          font-family: 'Value', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }

        .details-item--marker:before {
          content:"• ";
        }

        .margin-l-30 {
          margin-left: 30px;
        }

        details summary::-webkit-details-marker {
          display: none;
        }

        details > summary {
          list-style: none;
          font-family: 'Vanitas', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          text-transform: uppercase;
          color: $blue;
          margin-top: 15px;
          cursor: pointer;
        }

        details summary:before {
          content: url("../../../assets/images/closeListIcon.svg");
          margin-right: 5px;
        }

        details[open] summary:before {
          content: url("../../../assets/images/openListIcon.svg");
        }

        li {
          font-size: 10px;

          p {
            font-size: 12px;
            color: $blue;
            font-family: 'Value', sans-serif;
          }
        }
      }

      .shipping-mobile {
        margin-top: 20px;
        display: none;

        @include tablet {
          display: block;
        }
      }
    }
  }
}

.with-banner {
  .cart-block {
    top: $banner_height;

    @include tablet {
      top: $banner_tablet_height;
    }
  }
  .cart-block-fixed {
    @include tablet {
      top: calc($nav_tablet_height + $banner_tablet_height);
    }
    @include max_width(320px) {
      top: calc($nav_tablet_height + $banner_narrow_screen_height);
    }
  }

  .product-block {
    margin-top: calc($cart_block_height + $banner_height);

    @include tablet {
      top: calc($nav_tablet_height + $banner_tablet_height);
      margin-top: calc($nav_tablet_height + $banner_tablet_height);
    }
    @include max_width(320px) {
      top: calc($nav_tablet_height + $banner_narrow_screen_height);
      margin-top: calc($nav_tablet_height + $banner_narrow_screen_height);
    }
  }
}

.buttons-block {
  margin-top: 20px;

  button {
    margin-top: 5px;
  }

  @include tablet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 5px 20px 10px;
    box-shadow: 0 0 15px gray;
    z-index: 1;
  }
}
